import styled from 'styled-components';
import { Button } from '../../components/global/button';
import { PageSubTitle } from '../../components/global/page';
import { NormalText } from '../../components/global/utils';

export const SpeakerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 295px);

  @media only screen and (max-width: 900px) {
    justify-content: space-around;
  }

  @media only screen and (max-width: 768px) {
    align-self: center;
    width: fit-content;
  }
`;
export const Description = styled.div`
  font-family: 'Raleway';
  margin: 1.5rem 0;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 150%;
  color: #000;
`;

export const SignInButton = styled(Button)`
  width: 348px;
  margin-top: 3rem;
  margin-bottom: 3.75rem;
  @media only screen and (max-width: 768px) {
    width: unset;
  }
`;

export const Img = styled.img`
  object-fit: cover;
  object-position: center;
  max-width: 1180px;
  max-height: 350px;
  margin-bottom: 3.75rem;
`;

export const FullWidthTitle = styled(PageSubTitle)`
  color: white;
  max-width: 1000px;
`;

export const WhiteText = styled(NormalText)`
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  color: white;
`;
