import React from 'react';
import styled from 'styled-components';
import {
  Row,
  Column,
  navigateTo,
  Icon,
  Dot
} from '../../../components/global/utils';
import { Button } from '../../../components/global/button';
import {
  ContentBoxTitle,
  ContentBoxDescription,
  ContentBoxImg
} from '../../../components/global/contentbox';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const MoreInfoButton = styled(Button)`
  margin-left: 2rem;
  @media only screen and (max-width: 700px) {
    margin-left: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const DetailRow = styled(Row)`
  align-items: center;
  i {
    margin-left: 0;
    margin-right: 8px;
  }
`;

const IconRow = styled(Row)`
  margin: 0.5rem;
`;

const Wrapper = styled(Row)`
  border: 1px solid #4ebfee;
  border-radius: 16px;
  padding: 2rem 2rem;
  margin-bottom: 20px;
`;

/*TODO: fix image size*/

export const WorkshopDetails = ({ data, place, type }) => {
  const momentTime = moment(new Date(data));
  return (
    <DetailRow shouldWrap={true}>
      <IconRow>
        <Icon name="calendar" /> {momentTime.format('DD.MM.YYYY')}
      </IconRow>
      <Dot />
      <Icon name="time" /> {momentTime.format('HH:mm')}
      {place && (
        <>
          <Dot />
          <IconRow>
            <Icon name="pin" />
            {place}
          </IconRow>
        </>
      )}
      {type && (
        <>
          <Dot />
          <IconRow>
            <Icon name="people" /> {type}
          </IconRow>
        </>
      )}
    </DetailRow>
  );
};

export default function WorkshopBox(props) {
  const { t } = useTranslation();
  return (
    <Wrapper shouldBreakReverse>
      <Column>
        <ContentBoxTitle to={props.slug} style={{ fontWeight: '600' }}>
          {props.title}
        </ContentBoxTitle>
        <ContentBoxDescription style={{ lineHeight: '150%' }}>
          {props.description.description}
        </ContentBoxDescription>
        <WorkshopDetails {...props} />
        <Row shouldBreak={true} style={{ marginTop: '2rem' }}>
          <Button
            className="solid"
            onClick={() => navigateTo(props.link, '_blank')}>
            {props.button ? props.button : t`news.enter`}
          </Button>
          {props.slug && (
            <MoreInfoButton
              className="empty"
              onClick={() => navigateTo('/news/workshops/' + props.slug)}>
              {props.moreInfo}
            </MoreInfoButton>
          )}
        </Row>
      </Column>
      <ContentBoxImg
        src={props.thumbnail.file.url}
        alt={props.thumbnail.title}
        onClick={() => navigateTo('/news/workshops/' + props.slug)}
      />
    </Wrapper>
  );
}
