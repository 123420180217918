import React from 'react';
import { Column, Icon } from '../../../../components/global/utils';
import {
  SpeakerImg,
  SpeakerTitle,
  SpeakerDescription,
  LinkedinRow
} from './styled.components';

export const Speaker = (props) => {
  return (
    <Column style={{ marginBottom: '1rem' }} className="align-center">
      <SpeakerImg alt={[props.name]} src={props.photo.file.url} />
      <SpeakerTitle>{props.name}</SpeakerTitle>
      <SpeakerDescription>{props.description.description}</SpeakerDescription>
      {props.linkedin && (
        <LinkedinRow className="align-center">
          <Icon name="outlined-linkedin"></Icon>
          <a href={props.linkedin}>
            {props?.linkedin?.replace('www.', '').replace('https://', '')}
          </a>
        </LinkedinRow>
      )}
    </Column>
  );
};
