import React from 'react';
import moment from 'moment/moment';

import {
  Column,
  Icon,
  isWindow,
  Row
} from '../../../../components/global/utils';
import {
  Bar,
  AgendaTitle,
  Hour,
  StyledTag,
  Person,
  Label
} from './styled.components';
import {
  MobileRow,
  MobileAgendaTitle,
  MobileColumn,
  RoundedBox,
  ClockIcon
} from './styled.components';
import { Col } from '../../../../components/sections/styled.components';

const parseTimeBox = (timeBox) => {
  let hours = timeBox / 60;
  let rhours = Math.floor(hours);
  let minutes = Math.round((hours - rhours) * 60);
  return `${rhours ? rhours + 'h' : ''} ${minutes ? minutes + ' m' : ''}`;
};

/*TODO: Cos tu nie dziala, przy ostatnim warsztacie niektorzy speakerzy sie nie wyswietlili,
niektore wartosci widzi jako undefined, mozliwy problem w contentfulu*/

export const AgendaBar = (props, isEvent) => {
  return (
    isWindow() && (
      <Bar className={isEvent ? 'event-page' : ''}>
        <Row className="align-center">
          <Hour style={{ height: '100%' }} className="align-center">
            {moment(new Date(props.startTime)).format('HH:mm')}
          </Hour>
          {!isEvent ? (
            <AgendaTitle>{props.name}</AgendaTitle>
          ) : (
            <Col className="align-start small-gap">
              <AgendaTitle className="event">{props.name}</AgendaTitle>
              {props.eventSpeakers && (
                <AgendaTitle className="speaker">
                  {props.eventSpeakers}
                </AgendaTitle>
              )}
            </Col>
          )}
        </Row>
        <Row className="align-center">
          {!props.isBreak ? (
            !props.isEvent ? (
              <Person className="align-center">
                <img
                  alt={props?.speaker?.name}
                  className="cover"
                  src={props?.speaker?.photo?.file.url}
                />
                {props?.speaker?.name}
              </Person>
            ) : (
              <Label>
                {!props.activityType ? 'Panel' : props?.activityType}
              </Label>
            )
          ) : (
            props.isBreak && (
              <RoundedBox className="cover">
                <Icon name={props.icon} />
              </RoundedBox>
            )
          )}
          <StyledTag className="align-center">
            <ClockIcon name="clock"></ClockIcon>{' '}
            {parseTimeBox(props.panelLength)}
          </StyledTag>
        </Row>
      </Bar>
    )
  );
};

export const MobileAgendaBar = (props) => {
  return (
    isWindow() && (
      <MobileColumn>
        <MobileRow
          className="align-center"
          style={{ borderBottom: '1px solid #C4DFEC', paddingLeft: '1rem' }}>
          {!props.isBreak
            ? !props.isEvent && (
                <Person className="align-center">
                  <img
                    alt={props?.speaker?.name}
                    className="cover"
                    src={props?.speaker?.photo?.file.url}
                  />
                  {props?.speaker?.name}
                </Person>
              )
            : props.isBreak && (
                <RoundedBox className="cover">
                  <Icon name={props.icon} />
                </RoundedBox>
              )}
          {props.eventSpeakers ? (
            <Column
              style={{
                alignItems: 'start',
                justifyContent: 'center',
                gap: '4px'
              }}>
              {props.isEvent && (
                <Label style={{ width: '100%', textAlign: 'center' }}>
                  {props?.activityType ? props?.activityType : 'Panel'}
                </Label>
              )}
              <StyledTag style={{ marginLeft: '0px' }} className="align-center">
                <ClockIcon name="clock"></ClockIcon>{' '}
                {parseTimeBox(props.panelLength)}
              </StyledTag>
            </Column>
          ) : (
            <StyledTag className="align-center">
              <ClockIcon name="clock"></ClockIcon>{' '}
              {parseTimeBox(props.panelLength)}
            </StyledTag>
          )}
          {props.eventSpeakers && (
            <AgendaTitle className="speaker">{props.eventSpeakers}</AgendaTitle>
          )}
        </MobileRow>
        <MobileRow>
          <Hour className="align-center" style={{ marginRight: '0' }}>
            {moment(new Date(props.startTime)).format('HH:mm')}
          </Hour>
          <MobileAgendaTitle>{props?.name}</MobileAgendaTitle>
        </MobileRow>
      </MobileColumn>
    )
  );
};
