import styled from 'styled-components';
import { NormalText } from '../../../../components/global/utils';
import { Row } from '../../../../components/global/utils';

export const SpeakerImg = styled.img`
  width: 200px;
  height: 200px;
  border: 7px solid #2b95b7;
  border-radius: 50%;
  margin-bottom: 1.5rem;
`;
export const SpeakerTitle = styled(NormalText)`
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #0f5575;
  margin-bottom: 1rem;
`;
export const SpeakerDescription = styled(NormalText)`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #0f5575;
  margin-bottom: 1rem;
  max-width: 280px;
`;
export const LinkedinRow = styled(Row)`
  color: #5fa5c5;
  font-weight: 500;
  font-size: 14px;
  a {
    text-decoration: none;
    color: #5fa5c5;
  }
  i {
    margin-left: 0;
    margin-right: 8px;
  }
`;
